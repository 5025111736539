const NextI18Next = require('next-i18next').default;
const path = require('path');

module.exports = new NextI18Next({
    otherLanguages: ['en'],
    defaultLanguage: 'id',
    fallbackLng: ['id', 'en'],
    localePath: path.resolve('./public/static/locales'),
    // shallowRender: true,
    detection: {
        lookupCookie: 'next-i18next',
        order: ['cookie', 'querystring', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie'],
    },
});
